<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Update Profile</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-10">
        <form>
          <v-row>
           <v-col cols="12" md="6" sm="12" class="py-0">
              <v-autocomplete
                v-model="form_data.category_id"
                :error-messages="form_error.category_id"
                :items="categories"
                item-text="name"
                item-value="category_id"
                label="Category"
                outlined
                dense
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.company_name"
                :error-messages="form_error.company_name"
                label="Company Name"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.title"
                :error-messages="form_error.title"
                label="Title"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.phone_number"
                :error-messages="form_error.phone_number"
                label="Phone number"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-5">
            <v-col cols="12">
              <label>Company description</label>
              <vue-editor
                v-model="form_data.comapny_desc"
                :editor-toolbar="customToolbar"
                @text-change="getLength()"
              />
              <p v-if="getLength() > 200" class="red--text mt-3 mb-0">
                The company description will be less than 200 Characters
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="form_data.company_address"
                :error-messages="form_error.company_address"
                label="Address"
                outlined
                dense
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.email"
                :error-messages="form_error.email"
                label="Email"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.sponsor_type"
                :error-messages="form_error.sponsor_type"
                label="Sponsorship Level"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="my-0 py-1">
              <v-text-field
                v-model="form_data.social_links.facebook"
                :error-messages="form_error['social_links.facebook']"
                label="Facebook Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="my-0 py-1">
              <v-text-field
                v-model="form_data.social_links.linkedin"
                :error-messages="form_error['social_links.linkedin']"
                label="Linkedin Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="my-0 py-1">
              <v-text-field
                v-model="form_data.social_links.twitter"
                :error-messages="form_error['social_links.twitter']"
                label="Twitter Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="my-0 py-1">
              <v-text-field
                v-model="form_data.social_links.instagram"
                :error-messages="form_error['social_links.instagram']"
                label="Instagram Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="form_data.booth_color"
                :error-messages="form_error.booth_color"
                :items="booth_colors"
                item-text="name"
                item-value="color"
                label="Booth Color"
                outlined
                dense
                v-if="form_data.sponsor_type==4 || form_data.sponsor_type==5 || form_data.sponsor_type==6"
              ></v-select>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="my-0 py-1">
              <v-text-field
                v-model="form_data.social_links.youtube"
                :error-messages="form_error['social_links.youtube']"
                label="Youtube Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="updateProfile"
            :loading="loading"
            :disabled="loading || getLength() > 200"
            >Update</v-btn
          >
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
// import { extend } from 'vee-validate';
export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      form_data: {
        comapny_desc: "",
        social_links: {
          facebook: "",
          linkedin: "",
          twitter: "",
          instagram: "",
          youtube: "",
        },
        category_id : []
      },
      booth_colors: [
        { name: "Red", color: "red" },
        { name: "Red-Orange", color: "red_orange" },
        { name: "Orange", color: "orange" },
        { name: "Yellow", color: "yellow" },
        { name: "Yellow-Orange", color: "yellow_orange" },
        { name: "Yellow-Green", color: "yellow_green" },
        { name: "Green", color: "green" },
        { name: "Blue-Green", color: "blue_green" },
        { name: "Blue", color: "blue" },
        { name: "Blue-Violet", color: "blue_violet" },
        { name: "Violet", color: "violet" },
        { name: "Red-Violet", color: "red_violet" },
      ],
      file: "",
      showPreview: false,
      imagePreview: "",
      user_data: null,
      username: null,
      name: null,
      email: null,
      loading: false,
      data: {},
      categories : [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"]
      ],
    };
  },
  watch : {
    "form_data.comapny_desc" :{
      handler(val){
        var div = document.createElement("div");
        div.innerHTML = val;
        let links = div.querySelectorAll('a')
        for(let i=0;i<links.length;i++){
          if (!/^https?:\/\//i.test(links[i].getAttribute('href'))) {
            div.querySelectorAll('a')[i].setAttribute('href','http://' + links[i].getAttribute('href'))
          }
        }
        this.form_data.comapny_desc_html = div.innerHTML
      }
    }
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getLength() {
      var div = document.createElement("div");
      div.innerHTML = this.form_data.comapny_desc;
      return div.innerText.length;
    },
    updateProfile() {
      let _self = this;
      this.loading = "secondary";
      this.form_data.comapny_desc = this.form_data.comapny_desc_html
      this.$axios
        .post(`update_profile`, this.form_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            this.data = response_data.user_data;
            localStorage.setItem(
              "vendor_data",
              JSON.stringify(response_data.user_data)
            );
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/booth");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getDetails() {
      this.$axios
        .get(`me`)
        .then((res) => {
          this.form_error = {};
          if (res.status) {
            let data = res.data.data;
            delete data.category_id
            for (let key in data) {
              if(key == 'categories'){
                for(let key2 in data[key]){
                  this.form_data.category_id.push(data[key][key2].category_id)
                }
              }
              this.form_data[key] = data[key];
            }
          } else {
            let errors = res.error;
            for (let key in errors) {
              console.log(errors[key]);
              this.form_error[errors[key].field] = errors[key].message;
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    getCategoryList(){
      let _self = this;
      this.$axios.get(`category_list?pagination=false`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.categories = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getDetails();
    this.getCategoryList();
  },
};
</script>