import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
let opts = {
  theme: {
    themes : {
      light: {
        primary: '#6a4ba2',
        secondary: '#6a4ba2',
        accent: '#8c9eff',
        error: '#B71C1C'
      },
    }
  }
};
export default new Vuetify(opts);
